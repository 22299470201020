import { Config } from '@/types'

const config: Config = {
  siteTitle: "Jet Air MRO",
  siteFavicon: '/favicon.ico',
  logo: '/images/logo.png',
  navbarFixedOnScroll: true,
  captchaSiteKey: '6LfUFaUiAAAAAMJXn8LSmDuPJAeJlBA-lC1aiIFa',
}

export default config
export type { Config }
