import React, { useMemo, useRef } from 'react'
import { FormProvider } from '@/hook-form'
import RHInput from '@/hook-form/RHInput'
import RHTextarea from '@/hook-form/RHTextarea'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { saveContact, ContactData } from '@/npc-api/contact'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import ReCAPTCHA from 'react-google-recaptcha'
import toast from 'react-hot-toast'
import { useConfig } from '@/hooks/useConfig'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'

const Contact = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string(),
    message: Yup.string().required('Message is required'),
  })
  const { captchaSiteKey } = useConfig()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      company: '',
      message: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: ContactData) => {
    if (!recaptchaRef.current?.getValue()) {
      toast.error('Please check the Captcha field to validate you are not a robot')
      return
    }

    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    })

    await saveContact(data).then((e) =>
      reset({ name: '', phone: '', email: '', company: '', message: '' }),
    )
    recaptchaRef.current?.reset()
  }
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-7">
            <div className="contact_area">
              <div className="section-header">
                {/* <p className="section_count"></p> */}
                <h3 className="title">Get In Touch</h3>
              </div>
              <FormProvider
                name="fwadedamsn"
                methods={methods}
                onSubmit={handleSubmit(onSubmit)}
                className="row form_style style_two"
              >
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      autoComplete="off"
                      required
                    /> */}
                    <RHInput
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      autoComplete="off"
                      required
                    /> */}
                    <RHInput
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Your Email"
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <RHInput
                      name="phone"
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <RHInput
                      name="company"
                      type="text"
                      className="form-control"
                      placeholder="Company"
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Your Subject"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="form-group">
                    {/* <textarea
                      name="msg"
                      rows={5}
                      className="form-control"
                      placeholder="Write Something..."
                      autoComplete="off"
                      required
                    /> */}
                    <RHTextarea
                      className="form-control"
                      name="message"
                      placeholder="Your Message"
                      rows={5}
                    />
                  </div>
                </div>
                {captchaSiteKey && (
                  <div className="col-lg-12 mb-4">
                    <ReCAPTCHA sitekey={captchaSiteKey} ref={recaptchaRef} size="normal" hl="en" />
                  </div>
                )}
                <div className="col-lg-12">
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="thm-btn btn-rounded w-100"
                  >
                    <span className="button_title">
                      {' '}
                      {isSubmitting ? '...Loading' : 'Send Message'}
                    </span>
                  </button>
                </div>
              </FormProvider>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="h-100 contact_info thm-bg-color-two">
              <div className="section-header">
                <h3 className="title text-white">Contact Info</h3>
              </div>
              <div className="footer">
                <ul className="ft_contact ft_menu mb-xl-30">
                  <li>
                    <OutboundLink href="tel: +13054176625">
                      <i className="fas fa-phone-volume"></i>
                      305-417-6625
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink href="mailto: repairs@jetairmro.com">
                      <i className="fas fa-envelope"></i>
                      repairs@jetairmro.com
                    </OutboundLink>
                  </li>
                  <li>
                    <OutboundLink
                      href="https://maps.app.goo.gl/cCnCVgbU46a55MqB9"
                      target={'_blank'}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      3020 Gateway Dr, Pompano Beach, FL 33069
                    </OutboundLink>
                  </li>
                </ul>
                <ul className="ft_social">
                  <li>
                    <a href="https://www.facebook.com/jetairmro/">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  {/* <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li> */}
                  <li>
                    <a href="https://www.instagram.com/jetairmro">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/jet-air-mro/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="relative contact_map" id="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3590.8382239010793!2d-80.31078648545527!3d25.84187558360041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9ba45c3003dbb%3A0x3c92b3c4967f8ff3!2s6854%20NW%2075th%20St%20%235%2C%20Medley%2C%20FL%2033166!5e0!3m2!1ses!2sus!4v1657673078254!5m2!1ses!2sus"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
