import React from 'react'
import Layout from '@/components/Layout'
import Header from '@/components/Header'
import Contact from '@/components/Contact'
import Footer from '@/components/Footer'
import SubHeader from '@/components/Common/SubHeader'
import { useLocation } from '@reach/router'
import SEO from '@/components/seo'
const Con = () => {
  return (
    <Layout>
      <SEO title="Contact" canonical={useLocation().host} />
      <Header />
      <SubHeader title="Contact Us" />
      <Contact />
      <Footer />
    </Layout>
  )
}

export default Con
